import { useEffect, useState } from "react"
import { toast } from "react-toastify"

import { useAuth } from "@/Context/auth.context"
import { useCart } from "@/Context/cart.context"

import { API } from "../api"

export const useMembershipBooking = () => {
  const { cart, updateToken } = useCart()
  const { user } = useAuth()
  const [membershipItems, setMembershipItems] = useState<string[]>([])
  const [buttonLoading, setButtonLoading] = useState(false)

  useEffect(() => {
    if (cart) {
      const res = cart.lines.edges
        .filter((item) => {
          const title = item.node.merchandise.product.title.toLowerCase()
          // console.log(item.node)
          // console.log(title)
          return (
            title.includes("gold") ||
            title.includes("silver") ||
            title.includes("bronze")
          )
        })
        .map((res) => res.node.merchandise.id)
      setMembershipItems(res)
    }
  }, [cart])

  const handleBooking = async (sd: Date, assignImmediately?: string) => {
    if (!user) {
      toast.error("Please login first")
      return
    }

    // if (membershipItems.length === 0) return
    const shopifyIds = membershipItems
    const year = sd.getFullYear()
    const date = sd.getDate().toString().padStart(2, "0")
    const month = (sd.getMonth() + 1).toString().padStart(2, "0")

    const startdate = year + "-" + month + "-" + date
    setButtonLoading(true)
    try {
      console.clear()
      console.log({ cart })
      const token = await updateToken()
      await API.post(`/shopify/save-cart-data`, {
        startdate,
        shopifyIds,
        cartId: cart?.id,
        memberid: user.gid,
        token,
        assignImmediately,
      })
      console.clear()
      console.log({ cart })
      window.localStorage.removeItem("cartId")
      window.location.replace(cart?.checkoutUrl!)
      setButtonLoading(false)
    } catch (error) {
      toast.error("Could not save cart data")
      setButtonLoading(false)
      return
    }
  }

  const handleUnauthenticatedBooking = async (sd: Date, email: string) => {
    if (membershipItems.length === 0) return
    const shopify_id = membershipItems[0]
    const year = sd.getFullYear()
    const date = sd.getDate().toString().padStart(2, "0")
    const month = (sd.getMonth() + 1).toString().padStart(2, "0")

    const startdate = year + "-" + month + "-" + date
    setButtonLoading(true)
    const token = await updateToken()
    try {
      await API.post(`/shopify/save-cart-data`, {
        startdate,
        shopifyIds: [shopify_id],
        cartId: cart?.id,
        // memberid: user.gid,
        token,
        email,
        fetchUser: true,
        assignImmediately: shopify_id,
      })
      window.localStorage.removeItem("cartId")
      window.location.replace(cart?.checkoutUrl!)
      setButtonLoading(false)
    } catch (error) {
      toast.error("Could not save cart data")
      setButtonLoading(false)
      return
    }
  }

  return {
    handleBooking,
    buttonLoading,
    membershipItems,
    handleUnauthenticatedBooking,
  }
}
